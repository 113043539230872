<template>
<div>
  <TitleBar :workOrder="workOrder"/>
  <div class="wrapper">
    <!-- The list of notes -->
    <v-row>
      <v-col sm="12">
        <Card
          v-for="(templateNote, index) in notes"
          :key="index"
          :random-color="true"
          :avatar-text="getAvatarText(templateNote.name)"
          :title="templateNote.title"
          :description="getNoteDescription(templateNote)"
          :header-button="workOrder.status.name !== 'APPROVED' && !workOrder.is_in_pro_formas ? { action: () => triggerEditNoteDialog(templateNote), icon: 'mdi-pencil', color: 'grey-lighten-5', dark: false } : null"
        >
          <template v-slot:content>
            <p v-html="nl2br(templateNote.content)" />
            <div v-if="templateNote.images">
              <img
                v-for="image in templateNote.images"
                :key="imagePath(image)"
                :src="fromStorage(imagePath(image))"
                alt="image"
                class="image"
                @click="triggerImagePreview(image)"
              >
            </div>
          </template>
        </Card>
      </v-col>
    </v-row>

    <!-- Create note button -->
    <v-btn
      v-if="workOrder.status.name !== 'APPROVED' && !workOrder.is_in_pro_formas"
      icon
      color="orange darken-2"
      fab
      dark
      position="fixed"
      location="right bottom"
      class="note-create mb-14 mr-4"
      @click="triggerCreateNoteDialog"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

      <!-- Create/Edit note dialog -->
      <Dialog
        v-if="noteDialog"
        :visible.sync="noteDialog"
        @update:visible="noteDialog = $event"
        :title="note.id ? 'Bewerk notitie' : 'Maak notitie aan'"
      >
        <template v-slot:content>
          <v-text-field
            v-model="note.title"
            label="Titel"
          />
          <v-text-field
            v-model="note.content"
            label="Inhoud"
            multi-line
          />
          <input v-if="fileUpload" type="file" accept="image/*" multiple @change="onFileChange">
          <table v-if="note.images" class="table table-striped table-hover">
            <tr v-for="(image, imageIndex) in note.images" :key="imagePath(image)">
              <td>
                <img
                  :src="fromStorage(imagePath(image))"
                  class="image-preview"
                >
              </td>
              <td>
                <v-btn color="red" icon flat @click="imageDeleteConfirm(imageIndex)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </table>
        </template>
        <template v-slot:actions>
          <v-btn flat color="blue darken-2" @click.stop="noteDialog = false">
            Annuleren
          </v-btn>
          <v-spacer />
          <v-progress-circular v-if="noteLoading" indeterminate color="primary" />
          <v-btn v-if="!noteLoading" depressed color="blue darken-2" class="white--text" @click="noteSubmit">
            Opslaan
          </v-btn>
        </template>
      </Dialog>

      <!-- Image preview Dialog -->
      <Dialog
        v-if="imagePreviewDialog"
        :visible.sync="imagePreviewDialog"
        @update:visible="imagePreviewDialog = $event"
        content-class="dialog-image"
        :max-width="`80%`"
        :title="imagePreview"
      >
        <template v-slot:content>
          <img v-if="imagePreview" :src="fromStorage(imagePath(imagePreview))" class="dialog-image">
        </template>
      </Dialog>
    </div>
</div>
</template>

<script>
import filters from '@/utils/filters'
import { fetchWorkOrderNotes, createWorkOrderNote, updateWorkOrderNote, uploadWorkOrderNoteImages } from '@/api/workOrders'
import Card from '@/components/Card'
import Dialog from '@/components/Dialog'
import Say from '@/utils/Say'
import { nl2br, fromStorage, getAvatarText } from '@/utils/filters'
import TitleBar from '@/components/WorkOrders/TitleBar'

export default {
  components: {
    Card,
    Dialog,
    TitleBar,
  },
  data() {
    return {
      note: {},
      noteDialog: false,
      noteLoading: false,
      fileUpload: true,
      notes: [],
      imagePreviewDialog: false,
      imagePreview: { },
      nl2br,
      fromStorage,
      getAvatarText
    }
  },
  computed: {
    workOrder() {
      return this.$store.getters.workOrdersView
    },
  },
  mounted() {
    if (this.workOrder.id) {
      fetchWorkOrderNotes(this.workOrder.id)
        .then(res => {
          this.notes = res.data.map(note => {
            return note
          })
        })
    }
  },
  methods: {
    getNoteDescription(note) {
      return filters.dayMonthFromDateTime(note.created_at) + ' om ' + filters.hourMinutesFromDateTime(note.created_at) + ' door ' + note.name
    },
    triggerCreateNoteDialog() {
      this.note = {
        images: [],
      }
      this.noteDialog = true
    },
    triggerEditNoteDialog(note) {
      this.note = Object.assign({}, note)
      this.noteDialog = true
    },
    noteCreate() {
      return createWorkOrderNote(this.workOrder.id, this.note)
        .then(res => {
          const note = res.data
          this.notes.unshift(note)
        })
    },
    noteUpdate() {
      const { title, content, images } = this.note
      return updateWorkOrderNote(this.workOrder.id, this.note.id, { title, content, images })
        .then(() => {
          this.notes = this.notes.map(note => {
            if (note.id === this.note.id) {
              note.title = this.note.title
              note.content = this.note.content
            }
            return note
          })
        })
    },
    noteSubmit() {
      const method = this.note.id === undefined ? this.noteCreate : this.noteUpdate
      this.noteLoading = true
      method()
        .then(() => {
          this.noteLoading = false
          this.noteDialog = false
          Say('success', 'Opgeslagen')
        })
        .catch(err => {
          this.noteLoading = false
          Say('error', err.response?.data ?? err)
        })
    },
    // When files are selected, attempt their upload
    onFileChange(event) {
      this.fileUpload = false
      if (event.target.files.length !== 0) {
        const form = new FormData()
        for (const file of event.target.files) {
          form.append('images[]', file)
        }
        uploadWorkOrderNoteImages(this.workOrder.id, form)
          .then(res => {
            if (res.data.uploadedFiles.length !== 0) {
              const successMessage = 'Files ' + res.data.uploadedFiles.map(file => {
                // Add the files in here too
                if (this.note.images === null) {
                  this.note.images = [file]
                } else {
                  this.note.images.push(file)
                }

                // Return the filename for further use
                return file.filename
              }).join(', ') + ' were uploaded.'
              Say('success', successMessage)
            }
            if (res.data.errors.length !== 0) {
              Say('error', res.data.errors)
            }
            this.fileUpload = true
          })
          .catch(err => {
            Say('error', err.data)
            this.fileUpload = true
          })
      }
    },
    imagePath(filename) {
      return '/storage/workOrderNotes/' + filename
    },
    triggerImagePreview(filename) {
      this.imagePreviewDialog = true
      this.imagePreview = filename
    },
    imageDeleteConfirm(index) {
      const confirmAction = confirm('Are you sure you want to delete this image?')
      if (confirmAction) {
        this.note.images.splice(index, 1)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  .card {
    margin-bottom: 10px;
    .card__text {
      padding: 0;
    }
  }
}
.note-create {
  bottom: 64px;
}
.image {
  height: 100px;
  border: 1px solid #ddd;
  padding: 5px;
  margin-right: 10px;
}
.dialog-image, .image-preview {
  max-width: 100%;
}
</style>
